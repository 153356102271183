import React from "react";
import GroupScalesList from "../components/GroupScalesList";
import "./ScalesPage.css";

function ScalesPage() {
  return (
    <div id="scales-page">
      <div className="scales-page-header-container">
        <h3 id="scales-page-title">Scales</h3>
      </div>
      <div className="scales-container">
        <div className="scales-page-section">
          <GroupScalesList />
        </div>
      </div>
    </div>
  );
}

export default ScalesPage; 