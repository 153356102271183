import React, { useState, useEffect, useContext } from "react";
import { getGroupRequests, rejectRequest } from "../utils/FirestoreDB.js";
import { getFunctions, httpsCallable } from "firebase/functions";
import { UserContext } from "../context/UserContext";
import { capitalizeFirstLetter } from "../utils/stringUtils";
import strings from "../utils/strings";

import GroupUsersList from "../components/GroupUsersList";
import GroupUserInvites from "../components/GroupUserInvites";
import GroupJoinRequests from "../components/GroupJoinRequests";

import "./UsersPage.css";

function UsersPage() {
  const {
    userData,
    hasSubscription,
    isAdminOrOwner,
    isUserMaxed,
  } = useContext(UserContext);
  const functions = getFunctions();

  const [requests, setRequests] = useState([]);
  const [loadingRequests, setLoadingRequests] = useState(false);

  useEffect(() => {
    if (userData && userData.group_id && isAdminOrOwner) {
      fetchGroupRequests(userData.group_id);
    }
  }, [isAdminOrOwner, userData]);

  const userRoleDefinition = (role) => {
    switch (role) {
      case "owner":
        return strings.userOwnerRole;
      case "admin":
        return strings.userAdminRole;
      case "editor":
        return strings.userEditorRole;
      default:
        return strings.userDefaultRole;
    }
  };

  const fetchGroupRequests = async (groupId) => {
    setLoadingRequests(true);
    try {
      if (!groupId || !isAdminOrOwner) return;
      const requests = await getGroupRequests(groupId);
      setRequests(requests);
    } catch (error) {
      console.error("Error fetching group requests: ", error);
    } finally {
      setLoadingRequests(false);
    }
  };

  const handleApproveRequest = async (groupId, requestId) => {
    setLoadingRequests(true);
    try {
      const approveRequestFunc = httpsCallable(functions, "approveRequest");
      const result = await approveRequestFunc({ groupId, requestId });
      console.log(result.data.message);
      fetchGroupRequests(groupId);
    } catch (error) {
      console.error("Error approving request: ", error);
    } finally {
      setLoadingRequests(false);
    }
  };

  const handleRejectRequest = async (groupId, requestId) => {
    setLoadingRequests(true);
    try {
      await rejectRequest(groupId, requestId);
      fetchGroupRequests(groupId);
    } catch (error) {
      console.error("Error rejecting request: ", error);
    } finally {
      setLoadingRequests(false);
    }
  };

  return (
    <div id="users-page">
      <div className="users-page-header-container">
        <h3 id="users-page-title">Users Management</h3>
      </div>
      <div className="users-container">
        <div className="users-page-section">
          {/* User Role Section */}
          {hasSubscription && (
            <div className="user-role-section">
              <h3>Your Role</h3>
              {userData && userData.role ? (
                <>
                  <h4>{capitalizeFirstLetter(userData.role)}</h4>
                  <p>{userRoleDefinition(userData.role)}</p>
                </>
              ) : (
                <p>No user role assigned. Contact your group admin.</p>
              )}
            </div>
          )}

          {/* Existing Users Management Section */}
          {isAdminOrOwner && hasSubscription && (
            <>
              {!isUserMaxed && (
                <>
                  <GroupUserInvites />
                  <GroupJoinRequests
                    requests={requests}
                    loading={loadingRequests}
                    handleApproveRequest={handleApproveRequest}
                    handleRejectRequest={handleRejectRequest}
                  />
                </>
              )}
              <GroupUsersList />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default UsersPage; 