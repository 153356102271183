import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import strings from "./utils/strings";
import LocationManager from "./utils/LocationManager"; // Adjust the path as necessary

import "./App.css"; // Global styling
import "../src/styles/Common.css";
import "../src/styles/colors.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import Header from "./components/Header";
import ProtectedRoute from "./components/ProtectedRoute"; // Import updated ProtectedRoute

import HomePage from "./pages/HomePage";
import PartsListPage from "./pages/PartsListPage";
import PartEditorPage from "./pages/PartEditorPage";
import SettingsPage from "./pages/SettingsPage";
import AccountPage from "./pages/AccountPage";
import HelpPage from "./pages/HelpPage";
import NoAccessPage from "./pages/NoAccessPage";
import LoginPage from "./pages/LoginPage";
import AboutPage from "./pages/AboutPage";
import SubscriptionPage from "./pages/SubscriptionPage";
import SuccessPage from "./pages/SuccessPage";
import CancelPage from "./pages/CancelPage";
import InvitePage from "./pages/InvitePage";
import SubgroupPage from "./pages/SubgroupPage";
import ScalesPage from "./pages/ScalesPage";
import UsersPage from "./pages/UsersPage";

import LoadingSpinner from "./components/LoadingSpinner";

// Import UserContext
import { UserContext } from "./context/UserContext";
import { AuthContext } from "./context/AuthContext"; // Import your AuthContext

// Custom hook to update LocationManager
function useUpdateLocation() {
  const location = useLocation();

  useEffect(() => {
    LocationManager.setLocation(location);
  }, [location]);
}

// Custom hook to get user permissions from context
function useUserPermissions() {
  const { userData, loading, hasBasic, hasSubscription, isAdminOrOwner } =
    useContext(UserContext);

  // Check if the user has the appropriate role
  const userHasEditPermissions =
    (userData?.role === "admin" ||
      userData?.role === "editor" ||
      userData?.role === "owner") &&
    userData?.subscription_plan &&
    userData?.subscription_plan !== "free"; // Add subscription plan check

  const userIsOwner = userData?.role === "owner";

  return {
    userIsOwner,
    userHasEditPermissions,
    loading,
    hasBasic,
    hasSubscription,
    isAdminOrOwner,
  };
}

function Layout({ children }) {
  const location = useLocation();

  // Check if the current route is "/login"
  const isLoginPage = location.pathname === "/login";

  // If it's the login page, render the children directly without the layout
  if (isLoginPage) {
    return <>{children}</>; // Renders only the login page without the layout div
  }

  // Render the normal layout with the Header for all other routes
  return (
    <div className="layout">
      <Header />
      <main>{children}</main>
    </div>
  );
}

function App() {
  useUpdateLocation();

  const {
    userIsOwner,
    userHasEditPermissions,
    loading: roleLoading,
    hasBasic,
    hasSubscription,
    isAdminOrOwner,
  } = useUserPermissions(); // Get permissions from context
  const { user, loading: authLoading } = useContext(AuthContext); // Get authentication from AuthContext

  if (authLoading || roleLoading) {
    return <LoadingSpinner show={true} accessibilityLabel="Loading" />;
  }

  return (
    <Layout>
      <Routes>
        {/* Public Route: Login Page */}
        <Route path={strings.loginNav} element={<LoginPage />} />

        {/* rotected Routes: Home Page */}
        <Route
          path={strings.homepageNav}
          element={
            <ProtectedRoute userHasAccess={true /* Everyone can access this */}>
              <HomePage />
            </ProtectedRoute>
          }
        />

        {/* Protected Routes: Role-based and Auth-based */}
        <Route
          path={strings.partsNav}
          element={
            <ProtectedRoute userHasAccess={hasSubscription && !hasBasic}>
              <PartsListPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={strings.partEditorNav}
          element={
            <ProtectedRoute
              userHasAccess={
                userHasEditPermissions && hasSubscription && !hasBasic
              }
            >
              <PartEditorPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={strings.settingsNav}
          element={
            <ProtectedRoute userHasAccess={true}>
              <SettingsPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={strings.accountNav}
          element={
            <ProtectedRoute userHasAccess={!!user}>
              <AccountPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={strings.helpNav}
          element={
            <ProtectedRoute userHasAccess={true /* Help page is public */}>
              <HelpPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={strings.aboutNav}
          element={
            <ProtectedRoute userHasAccess={true}>
              <AboutPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={strings.subscriptionNav}
          element={
            <ProtectedRoute userHasAccess={userIsOwner}>
              <SubscriptionPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={strings.subgroupNav}
          element={
            <ProtectedRoute userHasAccess={isAdminOrOwner}>
              <SubgroupPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={strings.scalesNav}
          element={
            <ProtectedRoute userHasAccess={isAdminOrOwner}>
              <ScalesPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={strings.usersNav}
          element={
            <ProtectedRoute userHasAccess={isAdminOrOwner}>
              <UsersPage />
            </ProtectedRoute>
          }
        />

        <Route path={strings.inviteNav} element={<InvitePage />} />
        <Route path={strings.noAcessNav} element={<NoAccessPage />} />
        <Route path={strings.successNav} element={<SuccessPage />} />
        <Route path={strings.cancelNav} element={<CancelPage />} />
      </Routes>
    </Layout>
  );
}

export default App;
