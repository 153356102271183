import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable, getFunctions } from "firebase/functions";
import { capitalizeFirstLetter } from "../utils/stringUtils";
import strings from "../utils/strings";

import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { useBanner } from "../context/BannerContext";
import { useModal } from "../context/ModalContext";

import { navigateToSubscriptionPage } from "../utils/PageNav";

import { requestToJoinGroup } from "../utils/FirestoreDB.js";

import "./GroupDetails.css";

const GroupDetails = () => {
  const {
    user,
    userData,
    groupCounts,
    isOwner,
    isAdminOrOwner,
    refreshUserData,
    userSubgroup,
    hasBasic,
    hasSubscription,
  } = useContext(UserContext);
  const { showBanner } = useBanner();
  const { showActionModal, closeModal } = useModal();

  const navigate = useNavigate();
  const functions = getFunctions();

  const [groupInput, setGroupInput] = useState("");
  const [newGroupNameInput, setNewGroupNameInput] = useState("");

  const handleCreateNewGroup = async () => {
    try {
      const groupName = newGroupNameInput.trim();
      if (!groupName) {
        showBanner("Please enter a valid Group Name.", "error");
        return;
      }
      const createGroupFunc = httpsCallable(functions, "createGroup");
      const result = await createGroupFunc({ groupName });
      refreshUserData();
      showBanner(
        `Group ${groupName} (${result.data.groupId}) has been created!`,
        "success"
      );
    } catch (error) {
      console.error("Error creating new group: ", error);
      showBanner("An error occurred while creating the new group.", "error");
    }
  };

  const handleLeaveGroup = async () => {
    showActionModal(
      "Leave Group",
      "Are you sure you want to leave the group?",
      async () => {
        try {
          closeModal();
          const removeUserFunc = httpsCallable(
            functions,
            "removeUserFromGroup"
          );
          const result = await removeUserFunc({
            groupId: userData.group_id,
            userId: user.uid,
          });
          console.log(result.data.message);
          showBanner("You have successfully left the group.", "success");
          window.location.href = window.location.href;
        } catch (error) {
          console.error("Error leaving the group: ", error);
          showBanner(
            "An error occurred while trying to leave the group.",
            "error"
          );
        }
      },
      closeModal
    );
  };

  const handleJoinGroupRequest = async () => {
    try {
      const groupInputTrimmed = groupInput.trim();
      if (!groupInputTrimmed) {
        showBanner("Please enter a valid Group ID.", "error");
        return;
      }
      await requestToJoinGroup(groupInputTrimmed);
      showBanner(
        `Request to join group ${groupInputTrimmed} has been submitted.`,
        "success"
      );
    } catch (error) {
      console.error("Error requesting to join group: ", error);
      showBanner(
        "An error occurred while requesting to join the group.",
        "error"
      );
    }
  };

  const onManageSubscriptionClick = () => {
    navigateToSubscriptionPage(navigate);
  };

  const subscriptionDefinition = (plan) => {
    if (plan.toLowerCase().includes("basic")) {
      return strings.subTier1Desc;
    } else if (plan.toLowerCase().includes("standard")) {
      return strings.subTier2Desc;
    } else if (plan.toLowerCase().includes("premium")) {
      return strings.subTier3Desc;
    } else {
      return strings.subTier0Desc;
    }
  };

  const userRoleDefinition = (role) => {
    switch (role) {
      case "owner":
        return strings.userOwnerRole;
      case "admin":
        return strings.userAdminRole;
      case "editor":
        return strings.userEditorRole;
      default:
        return strings.userDefaultRole;
    }
  };

  return (
    <>
      <h3 className="section-title">Group Information</h3>
      <div className="section-content">
        {userData && userData.group_name && (
          <div className="group-info-item">
            <h4>Group Name</h4>
            <p>{userData.group_name}</p>
          </div>
        )}

        {userData && userData.group_id ? (
          <div className="group-info-item">
            <h4>Group ID</h4>
            <p>{userData.group_id}</p>
            {(!isOwner ||
              (groupCounts?.userCount <= 1 && !hasSubscription)) && (
              <button onClick={handleLeaveGroup}>Leave Group</button>
            )}
          </div>
        ) : (
          <div className="group-join-create">
            <p>Join an existing group or create a new group.</p>

            <div className="group-join-section">
              <h4>Join Existing Group</h4>
              <div>
                <input
                  type="text"
                  placeholder="Enter Group ID"
                  value={groupInput}
                  onChange={(e) => setGroupInput(e.target.value)}
                  className="account-page-text-input"
                />
                <button onClick={handleJoinGroupRequest}>
                  Request to Join Group
                </button>
              </div>
            </div>

            <div className="group-create-section">
              <h4>Create New Group</h4>
              <input
                type="text"
                placeholder="Enter Group Name"
                value={newGroupNameInput}
                onChange={(e) => setNewGroupNameInput(e.target.value)}
                className="account-page-text-input"
              />
              <button onClick={handleCreateNewGroup}>Create New Group</button>
            </div>
          </div>
        )}

        {/* Subscription Section - Moved to bottom */}
        <div className="subscription-section">
          <h3>Subscription Plan</h3>
          {userData && userData.subscription_plan?.toLowerCase() !== "free" ? (
            <>
              <h4>{capitalizeFirstLetter(userData.subscription_plan)}</h4>
              <p>{subscriptionDefinition(userData.subscription_plan)}</p>
            </>
          ) : (
            <>
              <h4>No Subscription</h4>
              <p>{subscriptionDefinition("free")}</p>
            </>
          )}
          {/* {console.log(isAdminOrOwner)} */}
          <button
            onClick={onManageSubscriptionClick}
            disabled={!isAdminOrOwner}
          >
            Manage Subscription
          </button>
          {!userData.group_id && 
          <p style={{color: "red"}}>Please setup your group before managing your subscription.</p>
          }
        </div>
      </div>
    </>
  );
};

export default GroupDetails;
