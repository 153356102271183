import React from 'react';
import { ModalProvider } from './ModalContext';
import { BannerProvider } from './BannerContext';
import { BannerOverlayProvider } from './BannerOverlayProvider';

const UIProvider = ({ children }) => {
  return (
    <ModalProvider>
      <BannerProvider>
        <BannerOverlayProvider>
          {children}
        </BannerOverlayProvider>
      </BannerProvider>
    </ModalProvider>
  );
};

export default UIProvider;
