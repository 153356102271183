import React, { createContext, useContext, useState, useCallback } from "react";
import { Paper, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "../styles/BannerOverlayProvider.css";

const BannerOverlayContext = createContext();

export const useBannerOverlay = () => useContext(BannerOverlayContext);

export const BannerOverlayProvider = ({ children }) => {
  const [bannerOverlay, setBannerOverlay] = useState({
    show: false,
    message: "",
    type: "info", // can be 'info', 'error', 'warning', or 'success'
    title: "",
    primaryAction: null,
  });

  const getIcon = (type) => {
    switch (type) {
      case "warning":
        return <WarningIcon sx={{ color: "#ff9800" }} />;
      case "error":
        return <ErrorIcon sx={{ color: "#f44336" }} />;
      case "success":
        return <CheckCircleIcon sx={{ color: "#4caf50" }} />;
      case "info":
      default:
        return <InfoIcon sx={{ color: "#2196f3" }} />;
    }
  };

  const showBannerOverlay = useCallback(
    ({ message, type = "info", title = "", primaryAction = null }) => {
      setBannerOverlay({
        show: true,
        message,
        type,
        title,
        primaryAction,
      });
    },
    []
  );

  const hideBannerOverlay = useCallback(() => {
    setBannerOverlay((prev) => ({ ...prev, show: false }));
  }, []);

  return (
    <BannerOverlayContext.Provider
      value={{ showBannerOverlay, hideBannerOverlay }}
    >
      {children}
      {bannerOverlay.show && (
        <Paper
          elevation={3}
          sx={{
            position: "fixed",
            bottom: 20,
            left: "50%",
            transform: "translateX(-50%)",
            padding: "16px 24px",
            display: "flex",
            alignItems: "center",
            gap: "16px",
            borderRadius: "16px",
            backgroundColor: "#fff",
            minWidth: "700px",
            maxWidth: "800px",
            boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
            zIndex: 1300,
            border: "1px solid #e0e0e0",
          }}
        >
          {getIcon(bannerOverlay.type)}

          <div style={{ flex: 1 }}>
            {bannerOverlay.title && (
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                {bannerOverlay.title}
              </Typography>
            )}
            <Typography variant="body2">{bannerOverlay.message}</Typography>
          </div>

          {bannerOverlay.primaryAction && (
            <Button
              size="small"
              variant="contained"
              onClick={bannerOverlay.primaryAction.onClick}
              sx={{
                backgroundColor: "#4a90e2",
                "&:hover": {
                  backgroundColor: "#357abd",
                },
                flexShrink: 0,
                mr: "16px",
                borderRadius: "16px",
              }}
            >
              {bannerOverlay.primaryAction.label}
            </Button>
          )}

          <IconButton
            size="small"
            onClick={hideBannerOverlay}
            sx={{
              color: (theme) => theme.palette.grey[600],
              width: "24px",
              height: "24px",
              padding: 0,
              minWidth: "24px",
              minHeight: "24px",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Paper>
      )}
    </BannerOverlayContext.Provider>
  );
};
