import React, { useState, useEffect, useContext } from "react";

import { getGroupRequests, rejectRequest } from "../utils/FirestoreDB.js";

import GroupUsersList from "../components/GroupUsersList";
import GroupUserInvites from "../components/GroupUserInvites";
import GroupJoinRequests from "../components/GroupJoinRequests";

import GroupScalesList from "../components/GroupScalesList.js";
import SignoutButton from "../components/SignoutButton.js";
import GroupDetails from "../components/GroupDetails";

import { UserContext } from "../context/UserContext";

import "./AccountPage.css";
import { getFunctions, httpsCallable } from "firebase/functions";

function AccountPage() {
  const {
    user,
    userData,
    hasSubscription,
    loading,
    refreshUserData,
    isAdminOrOwner,
    isUserMaxed,
  } = useContext(UserContext);
  const functions = getFunctions();

  const [requests, setRequests] = useState([]);
  const [loadingRequests, setLoadingRequests] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!loading && !userData) {
      refreshUserData(); 
    }
  }, [loading, userData, refreshUserData]);

  useEffect(() => {
    if (userData && userData.group_id && isAdminOrOwner) {
      fetchGroupRequests(userData.group_id);
    }
  }, [isAdminOrOwner, userData]);

  const fetchGroupRequests = async (groupId) => {
    setLoadingRequests(true);
    try {
      if (!groupId || !isAdminOrOwner) return;
      const requests = await getGroupRequests(groupId);
      setRequests(requests);
    } catch (error) {
      console.error("Error fetching group requests: ", error);
    } finally {
      setLoadingRequests(false);
    }
  };

  const handleApproveRequest = async (groupId, requestId) => {
    setLoadingRequests(true);
    try {
      const approveRequestFunc = httpsCallable(functions, "approveRequest");
      const result = await approveRequestFunc({ groupId, requestId });
      console.log(result.data.message);
      fetchGroupRequests(groupId);
    } catch (error) {
      console.error("Error approving request: ", error);
    } finally {
      setLoadingRequests(false);
    }
  };

  const handleRejectRequest = async (groupId, requestId) => {
    setLoadingRequests(true);
    try {
      await rejectRequest(groupId, requestId);
      fetchGroupRequests(groupId);
    } catch (error) {
      console.error("Error rejecting request: ", error);
    } finally {
      setLoadingRequests(false);
    }
  };

  return (
    <div id="account-page">
      <div className="account-page-header-container">
        <h3 id="account-page-title">Account</h3>
      </div>
      <div className="account-container">
        {/* Profile Section */}
        <div className="account-section">
          <h3 className="section-title">Profile Information</h3>
          <div className="section-content">
            {user && (
              <div className="profile-info-item">
                <div className="profile-name-section">
                  <h4>Name</h4>
                  <p>{user.displayName}</p>
                </div>
                <div className="profile-email-section">
                  <h4>Email</h4>
                  <p className="user-email">{user.email}</p>
                </div>
                <div className="profile-actions-section">
                  <SignoutButton />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Group Details Section */}
        <div className="account-section">
          <GroupDetails />
        </div>
      </div>
      {/* {isLoading && <LoadingSpinnerOverlay />} */}
    </div>
  );
}

export default AccountPage;
